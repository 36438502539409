import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from '../../components/News'
import StaffAugmentationLetsDiscussHeader from '../../components/StaffAugmentationLetsDiscussHeader'
import LetUsKnowRequirements from '../../components/LetUsKnowRequirements'

const StaffAugmentationLetsDiscuss = ({ data, location }) => {
  return (
    <div className='staffAugmentationLetsDiscuss' >
      <Layout pageTitle={"Visiomate - Staff Augmentation"} descrip = {"Need flexible staffing solutions? Staff augmentation provides access to skilled professionals, boosts productivity, and reduces costs. Get started now."}>
        <StaffAugmentationLetsDiscussHeader data={data} location={location} />
        <LetUsKnowRequirements location={location} />
        <News/>
      </Layout>
    </div>
  )
}

export default StaffAugmentationLetsDiscuss
export const webDevelopmentQuery = graphql`
  query staffAugmentationLetsDiscussQuery {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`

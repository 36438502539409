import React, { memo } from "react"
import { Typography } from "antd"
import { Link } from "gatsby"
import Img from "gatsby-image"
const { Title } = Typography

const StaffAugmentationLetsDiscussHeader = ({ data, location }) => {
  let topic = location.search.split("=")[1]

  return (
    <div
      className="top-section"
      style={{
        backgroundImage: `url(${data.bgImage?.childImageSharp.fluid.src})`,
      }}
    >
      <div className="stuffheaderlogo">
        <Link to="/" style={{ marginTop: "0px" }}>
          <Img fluid={data.VMLogoWhite.childImageSharp.fluid} alt="VMLOGO" />
        </Link>
      </div>
      <div className="headings-container" >
        {topic ? (
          topic === 'rjs' ? (
            <Title>React JS Experienced Developer</Title>
          ) : topic === 'drd' ? (
            <Title>D365 Retails/F&O Experienced Developer</Title>
          ) : topic === 'ned' ? (
            <Title>.Net Experienced Developer</Title>
          ) : topic === 'fed' ? (
            <Title>Flutter Experienced Developer</Title>
          ) : topic === 'oed' ? (
            <Title>Odoo Experienced Developer</Title>
          ) : (
            <Title>Hire our well equipt developer to increase productivity</Title>
          )
        ) : (
          <Title>Hire our well equipt developer to increase productivity</Title>
        )}
        <p className="staff_header_description">We commit to providing developers with a comprehensive development skills for faster, more efficient software augmentation.</p>

      </div>
    </div>
  )
}

export default memo(StaffAugmentationLetsDiscussHeader)

import React, { useState, memo } from "react"
import {
  Input,
  Upload,
  Button,
  Form,
  message,
  Select,
  Spin,
} from "antd"
import { UploadOutlined, CaretDownOutlined } from "@ant-design/icons"
import "../styles/infoModal.scss"
import { getBase64 } from "./../utils/utils"
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import { emails } from "../data"
import flag from "../images/2634382_ensign_flag_nation_pakistan_icon 2.png"

const LetUsKnowRequirements = ({
  url,
  companyIsEnabled,
  isModalVisible,
  handleOk,
  handleCancel,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const { TextArea } = Input

  const { Option } = Select;
  const handleChange = (value) => {
  };
  const onCaptchaChange = value => {
    setCaptchaValue(value)
  }

  const normFile = e => {
    if (!fileList?.length) {
      return []
    }
    return Array.isArray(e) ? e : e && e.fileList
  }

  const handleFormData = async values => {
    const {
      name,
      email,
      phone,
      projectDetails,
      company,
      file,
      budget,
      TypeOfResourcesRequired
    } = values;

    let attachment_base64 = null;

    if (file && file[0] && file[0].originFileObj) {
      attachment_base64 = await getBase64(file[0].originFileObj);
    }

    const formData = {
      name: "Staff Augmentation: " + name + " | " + TypeOfResourcesRequired,
      partner_name: company,
      email_from: email,
      phone,
      mobile: phone,
      description: projectDetails,
      attachment_base64,
      email_to: emails.info,
      budget,
    };

    sendFormData(formData);
  };
  const sendFormData = async (data) => {
    try {

      const response = await axios.post('https://staging.visiomate.com/crmPipeline', data);
      form.resetFields()
      message.success('Application submitted successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to submit application. Please try again later.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const onFinish = values => {
    setLoading(true);
    handleFormData(values)
  }

  const uploadProps = {
    fileList,
    accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx,",
    maxCount: 1,
    onRemove: file => {
      setFileList([])
    },
    beforeUpload: file => {
      const isLt200Kb = file.size / 1024 < 200;
      if (!isLt200Kb) {
        message.error("File must be smaller than 200kb!")
        setFileList([])
        return;
      }
      setFileList([file])
      return false
    },
  }

  return (
    <div className="LetUsKnowRequirements">
      <Form
        form={form}
        name="about"
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Full name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input placeholder="" type="email" />
        </Form.Item>
        <Form.Item
          label="Phone number (optional)"
          name="phone"
          rules={[
            {
              required: false,
              message: "Please input your Phone!",
            },
          ]}
        >
          <Input.Group compact>
            <Form.Item
              name='countryCode'
              noStyle
              initialValue="+92"

            >
              <Select className="ant-select-for-number"
                suffixIcon={<CaretDownOutlined className="CaretDownOutlinedForPhoneNumber" />}>
                <Option value="+92" className="flagWidth">
                  <img src={flag} className="flagWidth" alt="FlagForCode"/>
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='phone'
              noStyle
              rules={[
                {
                  required: false,
                  message: "Please input your Phone!",
                },
                {
                  pattern: /^\+92\d{3}\d{7}$/,
                  message: 'Please enter a valid phone number "+923000000000"'
                }
              ]}
            >
              <Input className="ant-input-for-number" placeholder="" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="Company (optional)"
          name="company"
          rules={[
            {
              required: false,
              message: "Please Enter Company Name!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Budget (optional)"
          name="budget"

          rules={[
            {
              required: false,
              message: "Please input your Phone!",
            },
          ]}
        >
          <Input placeholder="" type="number" />
        </Form.Item>
        <Form.Item
          label="Type of Resources Required"
          name="TypeOfResourcesRequired"
          rules={[
            {
              required: true,
              message: "Please select Resources Required!",
            },
          ]}
        >
          <Select
            placeholder="Select Type of Resources Required"
            onChange={handleChange}
            options={[
              {
                label: 'Flutter Experienced Developer',
                value: 'Flutter',
              },
              {
                label: 'React Experienced Developer',
                value: 'React',
              },
              {
                label: 'Odoo Experienced Developer',
                value: 'Odoo',
              },
            ]}
            rules={[
              {
                required: true,
                message: "Please Select Type Of Resources!",
              },
            ]}
            suffixIcon={<CaretDownOutlined className="CaretDownOutlined" />}

          />
        </Form.Item>
        <Form.Item label="Project Details" name="projectDetails" rules="" style={{marginBottom:"0"}}>
          <TextArea
            placeholder=""
            maxLength={100}
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
        </Form.Item>
        <p style={{textAlign:"end", marginBottom:"0"}}>100 Characters</p>
        <Form.Item
          label=""
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: false,
              message: "Please upload File!",
            },
          ]}
           style={{marginBottom:"0"}}
        >
          <Upload accept=".doc,.docx,.xml,.pdf" {...uploadProps} >
            <Button
              icon={<UploadOutlined />}
            >Attach a file
            </Button>
          </Upload>
        </Form.Item>
        <span className="file-limit-info">Max. size 200KB</span> 
        <ReCAPTCHA
          sitekey="6Le4Xc4pAAAAACdoP1-v8vNKtHOYfkJiXrOBvi8V"
          onChange={onCaptchaChange}
          style={{marginTop:"1rem"}}
        />
        <Form.Item
        >
          <Button className="button"
          style={{
            borderRadius:'5px'
          }}
            type="primary"
            htmlType="submit"
            disabled={captchaValue === null} >
            {loading ? <Spin /> : 'Send'}</Button>
        </Form.Item>
      </Form >
    </div >
  )
}
export default memo(LetUsKnowRequirements)
